<template>
  <div class="pa-4">
    <v-container fluid>
      <div class="align-center d-flex mb-2 ml-1">
        <h2 v-if="this.$store.state.user.role.startsWith('reception')" text color="black" class="headline">保管管理</h2>
        <h2 v-else text color="black" class="headline">在庫管理</h2>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="ml-3"
              @click="reloadInventries()"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="mx-3">mdi-reload</v-icon>
            </v-btn>
          </template>
          <span>再読み込み</span>
        </v-tooltip>


      </div>
      <v-row dense>

        <!-- 医療機関 -->
        <v-col
          v-if="
            this.$store.state.user.role.startsWith('customer')
            || this.$store.state.user.role.startsWith('management')
            || this.$store.state.user.role.startsWith('center')
          "
          :cols="this.$store.state.user.role.startsWith('customer') ? 12 : 6"
        >
          <v-toolbar
            color="primary"
            flat
            dark
            class="top-rounded"
            height="80px"
          >
            <v-row class="align-center ml-1 mr-1">
              <v-col cols="12" sm="5">
                医療機関在庫数
              </v-col>
              <v-col cols="12" sm="7">
                <v-text-field
                  dense
                  label="検索キーワード"
                  outlined
                  clearable
                  hide-details
                  v-model="searchKeywordCustomerOrgs"
                  prepend-inner-icon="search"
                  @blur="subscribeCustomerOrgs"
                  @keyup.enter="subscribeCustomerOrgs"
                />
              </v-col>
            </v-row>
          </v-toolbar>
          <v-data-table
            :headers="headers.default"
            :items="customerOrgs"
            :items-per-page.sync=selectedLinesPerPage
            hide-default-footer
            fixed-header
            height="700"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                :items-per-page-options="linesPerPageList"
                class="border-less"
              />
            </template>
            <template v-slot:[`item.holter_stock_quantity`]="{ item }">
              <div  align="center">
                {{ item.holter_stock_quantity ? item.holter_stock_quantity : 0 }} 個
              </div>
            </template>
            <template v-slot:[`item.dongle_stock_quantity`]="{ item }">
              <div align="center">
                {{ item.dongle_stock_quantity ? item.dongle_stock_quantity : 0 }} 個
              </div>
            </template>
          </v-data-table>
        </v-col>

        <!-- デバイス受付センター-->
        <v-col
          v-if="
            this.$store.state.user.role.startsWith('reception')
            || this.$store.state.user.role.startsWith('management')
          "
          :cols="this.$store.state.user.role.startsWith('reception') ? 12 : 6"
        >
          <v-toolbar
            color="primary"
            flat
            dark
            class="top-rounded"
            height="80px"
          >
            <v-row class="align-center ml-1 mr-1">
              <v-col cols="12" sm="5">
                デバイス受付センター
              </v-col>
              <v-col cols="12" sm="7">
                <v-text-field
                  dense
                  label="検索キーワード"
                  outlined
                  clearable
                  hide-details
                  v-model="searchKeywordReceptions"
                  prepend-inner-icon="search"
                  @blur="subscribeReceptions"
                  @keyup.enter="subscribeReceptions"
                />
              </v-col>
            </v-row>
          </v-toolbar>
          <v-data-table
            :headers="headers.receptions"
            :items="receptions"
            :items-per-page.sync=selectedLinesPerPage
            hide-default-footer
            fixed-header
            height="700"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                :items-per-page-options="linesPerPageList"
                class="border-less"
              />
            </template>
            <template v-slot:[`item.holter_stock_quantity`]="{ item }">
              <div  align="center">
                {{ item.holter_stock_quantity ? item.holter_stock_quantity : 0 }} 個
              </div>
            </template>
            <template v-slot:[`item.holter_stock_quantity_keeped`]="{ item }">
              <div align="center">
                {{ item.holter_stock_quantity_keeped ? item.holter_stock_quantity_keeped : 0 }} 個
              </div>
            </template>
            <template v-slot:[`item.holter_stock_quantity_disposed`]="{ item }">
              <div align="center">
                {{ item.holter_stock_quantity_disposed ? item.holter_stock_quantity_disposed : 0 }} 個
              </div>
            </template>
            <template v-slot:[`item.holter_stock_quantity_all`]="{ item }">
              <div align="center">
                {{ getHolterStockQuantityAll(item) }} 個
              </div>
            </template>
          </v-data-table>
        </v-col>

      </v-row>
    </v-container>
  </div>
</template>

<script>
import dateformat from 'dateformat';

export default {
  name: 'Inventories',
  components: {
  },
  mixins:[
  ],
  data() {
    return {
      itemsName: 'inventories',
      headers: {
        default: [
          {
            text: '組織名',
            align: 'left',
            sortable: true,
            value: 'name',
            width: '160px',
          },
          {
            text: 'ホルター心電計',
            align: 'center',
            sortable: true,
            value: 'holter_stock_quantity',
            width: '160px',
          },
          {
            text: '通信用ドングル／ソフト',
            align: 'center',
            sortable: true,
            value: 'dongle_stock_quantity',
            width: '160px',
          },
        ],
        receptions: [
          {
            text: '組織名',
            align: 'left',
            sortable: true,
            value: 'name',
            width: '160px',
          },
          {
            text: '受付数',
            align: 'center',
            sortable: true,
            value: 'holter_stock_quantity',
            width: '160px',
          },
          {
            text: '保管数',
            align: 'center',
            sortable: true,
            value: 'holter_stock_quantity_keeped',
            width: '160px',
          },
          {
            text: '廃棄数',
            align: 'center',
            sortable: true,
            value: 'holter_stock_quantity_disposed',
            width: '160px',
          },
          {
            text: '総数',
            align: 'center',
            sortable: true,
            value: 'holter_stock_quantity_all',
            width: '160px',
          },
        ]
      },
      items: [],
      searchKeywordCenters: '',
      searchKeywordReceptions: '',
      searchKeywordCustomerOrgs: '',
      centers:[],
      customerOrgs:[],
      receptions:[],
      selectedLinesPerPage: 10,
      linesPerPageList: [10, 30, 50],
    };
  },
  computed: {
    logDateFormatted() {
      return this.logDate.toLocaleDateString().replace(/\//g, '-');
    }
  },
  methods: {
    getHolterStockQuantityAll(item) {
      const keeped = item.holter_stock_quantity_keeped ? item.holter_stock_quantity_keeped : 0;
      const collected = item.holter_stock_quantity ? item.holter_stock_quantity : 0;
      const disposed = item.holter_stock_quantity_disposed ? item.holter_stock_quantity_disposed : 0;
      return keeped + collected + disposed;
    },
    timestampToString(timestamp){
      console.log(timestamp);
      try {
        return timestamp ? dateformat(new Date(timestamp.seconds * 1000), 'yyyy/mm/dd HH:MM:ss'): '';
      } catch (e) {
        return '';
      }
    },
    subscribeCenters() {
      // 種別訳
      this.centers = this.$store.state.centers;
      let keyword = this.searchKeywordCenters ? this.searchKeywordCenters.trim(): '';
      if (keyword != '') {
        // 変更
        let newCenters = [];
        this.centers.forEach(center => {
          if (center.name.indexOf(keyword) >= 0){
            newCenters.push(center);
          }
        });
        this.centers = newCenters;
      }
    },
    subscribeCustomerOrgs() {
      this.customerOrgs = this.$store.state.customerOrgs;
      if (this.$store.state.user.role.startsWith('customer')) {
        // 医療機関スタッフの場合は、自組織のみ
        this.customerOrgs = this.customerOrgs.filter(org => org.id == this.$store.state.user.org_id);
      }
      let keyword = this.searchKeywordCustomerOrgs ? this.searchKeywordCustomerOrgs.trim(): '';
      if (keyword != '') {
        // 変更
        let newOrgs = [];
        this.customerOrgs.forEach(org => {
          if (org.name.indexOf(keyword) >= 0){
            newOrgs.push(org);
          }
        });
        this.customerOrgs = newOrgs;
      }
    },
    subscribeReceptions() {
      this.receptions = this.$store.state.receptions;
      let keyword = this.searchKeywordReceptions ? this.searchKeywordReceptions.trim(): '';
      if (keyword == '') return;
      let newOrgs = [];
      this.receptions.forEach(org => {
        if (org.name.indexOf(keyword) >= 0){
          newOrgs.push(org);
        }
      });
      this.receptions = newOrgs;
    },
    reloadInventries() {
      this.subscribeCenters();
      this.subscribeReceptions();
      this.subscribeCustomerOrgs();
    },
    isSwitchingUser() {
      // superUserが存在しない場合は、なりすまし不可
      if (!this.$store.state.superUser) {
        return false;
      }
      // ログイン中のユーザーIDと、superUserのIDが同一の場合はなりすましではない
      if (this.$store.state.user.id == this.$store.state.superUser.id) {
        return false;
      }
      return true;
    }
  },
  watch: {
    selectedLinesPerPage(lineNumber) {
      if (this.$store.state.user.table_lines_par_page != lineNumber) {
        const staffsRef = this.$db
          .collection('staffs')
          .doc(this.$store.state.user.id);
        // 運営主体管理ユーザーによる、なりすましの場合は更新しない
        if (!this.isSwitchingUser()) {
          staffsRef.update({
            table_lines_par_page: lineNumber
          });
        }
        this.$store.commit('setUserTableLinesParPage', lineNumber);
      }
    }
  },
  mounted() {
    // テーブル表示件数の初期読み込み
    if (this.$store.state.user.table_lines_par_page) {
      this.selectedLinesPerPage = this.$store.state.user.table_lines_par_page;
    }
    // 他の画面で表示件数が変更された場合、こちらの画面にも反映させる
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setUserTableLinesParPage') {
        this.selectedLinesPerPage = state.user.table_lines_par_page;
      }
      if (mutation.type === 'setCenters') {
        this.subscribeCenters();
      }
      if (mutation.type === 'setReceptions') {
        this.subscribeReceptions();
      }
      if (mutation.type === 'setCustomerOrgs') {
        this.subscribeCustomerOrgs();
      }
    });

    this.subscribeCenters();
    this.subscribeReceptions();
    this.subscribeCustomerOrgs();
  },
  beforeDestroy() {
  }
}
</script>

<style scoped>
  .top-rounded {
    margin:0px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .border-less {
    border: none !important;
  }
</style>